import React, { useState, useEffect } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const {t} = useTranslation();

  const breakpoints = useBreakpoint();
  const [isLarge, setLargeOpen] = useState(true);
  const [isProduitsOpen, setProduitsOpen] = useState(isLarge);
  const [isExclusivitésOpen, setExclusivitésOpen] = useState(isLarge);
  const [isCasdusageOpen, setCasdusageOpen] = useState(isLarge);
  const [isEntrepriseOpen, setEntrepriseOpen] = useState(isLarge);
  const [isRessourcesOpen, setRessourcesOpen] = useState(isLarge);

  useEffect(() => {
    setLargeOpen(breakpoints.md ? false : true);
  }, [breakpoints.md]);

  useEffect(() => {
    setProduitsOpen(isLarge);
    setExclusivitésOpen(isLarge);
    setCasdusageOpen(isLarge);
    setEntrepriseOpen(isLarge);
    setRessourcesOpen(isLarge);
  }, [isLarge]);

  const Produits = [
    { route: "/plateforme", externalLink: false, title: `${t('platform')}` },
    { route: "/extension", externalLink: false, title: "Extension" },
    {
      route: "/lockdown-browser",
      externalLink: false,
      title: "Lockdown Browser",
    },
    { route: "/évaluation", externalLink: false, title: `${t('evaluation')}` },
    { route: "/analyse", externalLink: false, title: "Analyse" },
  ];

  const Exclusivités = [
    {
      route: "/expérience-candidat",
      externalLink: false,
      title: "Candidate Friendly",
    },
    { route: "/support", externalLink: false, title: "Support" },
    {
      route: "/scalabilité",
      externalLink: false,
      title: `${t('scalability')}`,
    },
    { route: "/flexibilité", externalLink: false, title: `${t('flexibility')}` },
    {
      route: "/protection-des-données",
      externalLink: false,
      title: `${t('data_protection')}`,
    },
    {
      route: "/intelligence-artificielle",
      externalLink: false,
      title: `${t('automated_monitoring')}`,
    },
  ];

  const Casdusage = [
    {
      route: "/test-de-sélection",
      externalLink: false,
      title: `${t('standardized_test')}`,
    },
    {
      externalLink: false,
      route: "/examens",
      title: `${t('higher_education')}`,
    },
    {
      externalLink: false,
      route: "/certification",
      title: "Certification",
    },
    {
      externalLink: false,
      route: "/test-de-recrutement",
      title: `${t('professional_recruitment')}`,
    },
    {
      externalLink: false,
      route: "/formation-professionnelle",
      title: `${t('professional_training')}`,
    },
  ];

  const Entreprise = [
    { route: "/notre-mission", externalLink: false, title: `${t('about_us')}` },
    {
      route: "/client",
      externalLink: false,
      title: "Clients",
    },
    // { route: "/partners", title: "Partenaires" },
    { route: "/contact", externalLink: false, title: "Contact" },
    // { route: "/prices", title: "Pricing" },
    {
      route: "https://angel.co/company/mereos-1",
      externalLink: true,
      title: `${t('career')}`,
    },
    { route: "/demo", externalLink: false, title: `${t('costs')}` },
  ];

  const Ressources = [
    {
      route: "/ressources",
      externalLink: false,
      title: `${t('white_papers')}`
    },
    { 
      route: "https://mereos.eu/blog",
      externalLink: false,
      title: "Blog"
    },
    { 
      route: "https://mereos.eu/blog/newsroom/",
      externalLink: false,
      title: `${t('press_room')}`
    },
    // { route: "/",externalLink:false, title: "Knowledge Base" },
    {
      route: "https://mereos.zendesk.com",
      externalLink: false,
      title: "FAQ"
    },
    {
      route: "https://mereos.zendesk.com",
      externalLink: false, 
      title: `${t('help_support')}`
    },
  ];

  const SocialLinks = [
    {
      title: "facebook",
      link: "https://www.facebook.com/mereos.edu/",
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/facebook-icon.svg",
    },
    {
      title: "Github",
      link: "https://github.com/Drone9",
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/github-icon.svg"
    },
    {
      title: "instagram",
      link: "https://www.instagram.com/mereos.education/",
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/instagram-icon.svg"
    },
    {
      title: "LinkedIn",
      link: "https://fr.linkedin.com/company/mereos",
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/linkedin-icon.svg",
    },
    {
      title: "Twitter",
      link: "https://twitter.com/mereoseducation",
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/twitter-icon.svg",
    },
  ];

  return (
    <>
      <footer className={`bg-black py-10 px-12 text-white font-sans `}>
        <div
          className={`flex flex-col md:flex-row lg:flex-nowrap md:flex-wrap   justify-between md:w-full 2xl:w-11/12 my-12 md:mx-auto`}
        >
          <div className={`list-none mx-3`}>
            <div
              className="text-xl flex flex-row items-center font-extrabold mb-3 cursor-pointer"
              onClick={() => {
                if (breakpoints.md) {
                  setProduitsOpen(!isProduitsOpen);
                }
              }}
            >
              {t('products')}
              <span
                className={`block md:hidden mx-4 pb-3 ${
                  !isProduitsOpen
                    ? " transform rotate-180 -translate-y-1"
                    : "transform translate-y-1"
                }`}
              >
                <img
                  alt="footer"
                  src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_white_icon.svg"
                  className="my-0"
                />
              </span>
            </div>
            {isProduitsOpen
              ? Produits.map((item, index) => {
                  return (
                    <Link to={item.route} key={index}>
                      <li className="cursor-pointer font-extralight text-gray-400">
                        {item.title}
                      </li>
                    </Link>
                  );
                })
              : ""}
          </div>
          <div className={`list-none mx-3`}>
            <div
              className="text-xl flex flex-row items-center font-extrabold mb-3 cursor-pointer"
              onClick={() => {
                if (breakpoints.md) {
                  setExclusivitésOpen(!isExclusivitésOpen);
                }
              }}
            >
              {t('exclusives')}
              <span
                className={`block md:hidden mx-4 pb-3 ${
                  !isExclusivitésOpen
                    ? " transform rotate-180 -translate-y-1"
                    : "   transform translate-y-1"
                }`}
              >
                <img
                  alt="footer"
                  src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_white_icon.svg"
                  className="my-0"
                />
              </span>
            </div>
            {isExclusivitésOpen
              ? Exclusivités.map((item, index) => {
                  return (
                    <Link to={item.route} key={index}>
                      <li className="cursor-pointer font-extralight text-gray-400 ">
                        {item.title}
                      </li>
                    </Link>
                  );
                })
              : ""}
          </div>
          <div className={`list-none mx-3`}>
            <div
              className="text-xl flex flex-row items-center font-extrabold mb-3 cursor-pointer"
              onClick={() => {
                if (breakpoints.md) {
                  setCasdusageOpen(!isCasdusageOpen);
                }
              }}
            >
              {t('use_cases')}
              <span
                className={`block md:hidden mx-4 pb-3 ${
                  !isCasdusageOpen
                    ? " transform rotate-180 -translate-y-1"
                    : "transform translate-y-1"
                }`}
              >
                <img
                  alt="footer"
                  src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_white_icon.svg"
                  className="my-0"
                />
              </span>
            </div>
            {isCasdusageOpen
              ? Casdusage.map((item, index) => {
                  return item.externalLink ? (
                    <a href={item.route} key={index}>
                      <li className="cursor-pointer font-extralight text-gray-400">
                        {item.title}
                      </li>
                    </a>
                  ) : (
                    <Link to={item.route} key={index}>
                      <li className="cursor-pointer font-extralight text-gray-400">
                        {item.title}
                      </li>
                    </Link>
                  );
                })
              : ""}
          </div>
          <div className={`list-none mx-3`}>
            <div
              className="text-xl flex flex-row items-center font-extrabold mb-3 cursor-pointer lg:disabled"
              onClick={() => {
                if (breakpoints.md) {
                  setEntrepriseOpen(!isEntrepriseOpen);
                }
              }}
            >
              Entreprise{" "}
              <span
                className={`block md:hidden mx-4 pb-3 ${
                  !isEntrepriseOpen
                    ? " transform rotate-180 -translate-y-1"
                    : "transform translate-y-1"
                }`}
              >
                <img
                  alt="footer"
                  src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_white_icon.svg"
                  className="my-0"
                />
              </span>
            </div>
            {isEntrepriseOpen
              ? Entreprise.map((item, index) => {
                  return item.externalLink ? (
                    <a href={item.route} key={index}>
                      <li className="cursor-pointerfont-extralight text-gray-400">
                        {item.title}
                      </li>
                    </a>
                  ) : (
                    <Link to={item.route} key={index}>
                      <li className="cursor-pointerfont-extralight text-gray-400">
                        {item.title}
                      </li>
                    </Link>
                  );
                })
              : ""}
          </div>
          <div className={`list-none mx-3`}>
            <div
              className="flex flex-row items-center text-xl font-extrabold mb-3 cursor-pointer"
              onClick={() => {
                if (breakpoints.md) {
                  setRessourcesOpen(!isRessourcesOpen);
                }
              }}
            >
              Ressources
              <span
                className={`block md:hidden mx-4 pb-3  ${
                  !isRessourcesOpen
                    ? " transform  rotate-180 -translate-y-1"
                    : "transform translate-y-1"
                }`}
              >
                <img
                  alt="footer"
                  src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_white_icon.svg"
                  className="my-0"
                />
              </span>
            </div>
            {isRessourcesOpen
              ? Ressources.map((item, index) => {
                  return (
                    <Link to={item.route} key={index}>
                      <li className="cursor-pointer font-extralight text-gray-400">
                        {item.title}
                      </li>
                    </Link>
                  );
                })
              : ""}
          </div>
        </div>
        <div>
          <div className="mx-auto flex flex-row justify-center cursor-pointer">
            {SocialLinks.map((i, index) => {
              return (
                <a href={i.link} key={index}>
                  <img alt="footer" src={i.iconSrc} className="w-8 mx-4" />
                </a>
              );
            })}
          </div>
          <div className="flex flex-row justify-center mx-auto my-2 space-x-5">
            <Link
              to={'/conditions-générales'}
              className="cursor-pointer"
            >
              {t('terms_and_conditions_of_use')}
            </Link>
            <Link
              to={'/politique-de-cookies'}
              className="cursor-pointer"
            >
              Cookies
            </Link>
            <Link
              to={'/mentions-légales'}
              className="cursor-pointer"
            >
              {t('terms_of_use')}
            </Link>
            <Link
              to={'/politique-de-confidentialité'}
              className="cursor-pointer"
            >
              {t('privacy_policy')}
            </Link>
          </div>
          <div className="flex flex-row justify-center my-2 mx-auto">
            &copy; {new Date().getUTCFullYear()} Mereos
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
